import { find } from 'lodash';
import { EN_GB_LOCALE } from 'variables';
import {
  LIMIT_PAYMENT_TYPE_ALL,
  LIMIT_PAYMENT_TYPE_ATM,
  LIMIT_PAYMENT_TYPE_ATM_BALANCE,
  LIMIT_PAYMENT_TYPE_ATM_CHANGE_PIN,
  LIMIT_PAYMENT_TYPE_CASH,
  LIMIT_PAYMENT_TYPE_CASHBACK,
  LIMIT_PAYMENT_TYPE_CNP,
  LIMIT_PAYMENT_TYPE_CONTACTLESS,
  LIMIT_PAYMENT_TYPE_NON_CASH,
  LIMIT_PAYMENT_TYPE_POS,
} from './constants';

const findCardLimit = (cardLimits, type) =>
  find(cardLimits, ({ payment_type: paymentType }) => paymentType === type);

const firstLevelHierarchy = {
  hierarchy: {
    level: 1,
  },
};

const secondLevelHierarchy = {
  hierarchy: {
    level: 2,
  },
};

const thirdLevelHierarchy = {
  hierarchy: {
    level: 3,
  },
};

export const orderCardLimits = (cardLimits) =>
  cardLimits?.length > 0
    ? [
        findCardLimit(cardLimits, LIMIT_PAYMENT_TYPE_ALL),
        {
          ...findCardLimit(cardLimits, LIMIT_PAYMENT_TYPE_CASH),
          ...firstLevelHierarchy,
        },
        {
          ...findCardLimit(cardLimits, LIMIT_PAYMENT_TYPE_ATM),
          ...secondLevelHierarchy,
        },
        {
          ...findCardLimit(cardLimits, LIMIT_PAYMENT_TYPE_ATM_BALANCE),
          ...secondLevelHierarchy,
        },
        {
          ...findCardLimit(cardLimits, LIMIT_PAYMENT_TYPE_ATM_CHANGE_PIN),
          ...secondLevelHierarchy,
        },
        {
          ...findCardLimit(cardLimits, LIMIT_PAYMENT_TYPE_CASHBACK),
          ...secondLevelHierarchy,
        },
        {
          ...findCardLimit(cardLimits, LIMIT_PAYMENT_TYPE_NON_CASH),
          ...firstLevelHierarchy,
        },
        {
          ...findCardLimit(cardLimits, LIMIT_PAYMENT_TYPE_POS),
          ...secondLevelHierarchy,
        },
        {
          ...findCardLimit(cardLimits, LIMIT_PAYMENT_TYPE_CONTACTLESS),
          ...thirdLevelHierarchy,
        },
        {
          ...findCardLimit(cardLimits, LIMIT_PAYMENT_TYPE_CNP),
          ...secondLevelHierarchy,
        },
      ].filter(({ payment_type: paymentType }) => paymentType != null)
    : [];

export const formatCurrency = (amount, currency, options = {}) => {
  if (amount == null) {
    return '';
  }

  const { locale = EN_GB_LOCALE, maximumFractionDigits = 2 } = options;

  const formatter = new Intl.NumberFormat(locale, {
    maximumFractionDigits,
    style: 'currency',
    currency,
  });

  return formatter.format(amount);
};

export const formatBankLimit = (amount, currency, options = {}) => {
  if (amount == null) {
    return '';
  }

  return formatCurrency(amount / 100, currency, options);
};
