import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { map, toString, keys } from 'lodash/fp';
import Tooltip from 'components/Tooltip';
import Indicator from 'components/Indicator';
import { ReactComponent as HierarchyArrow } from 'assets/hierarchy-arrow.svg';
import { HIERARCHY_LEVEL_SPACING } from 'components/Limits/constants';
import { StyledText } from './BodyCellStyled';

const Content = styled('div')(
  ({ centered }) => `
    display: flex;
    align-items: center;

    ${
      centered &&
      `
        display: flex;
        justify-content: center;
        width: 100%;
      `
    }
  `
);

const Hierarchy = styled('div')(
  ({ theme, level }) => `
    display: flex;
    align-items: center;
    margin-right: ${theme.spacings.byte};
    margin-left: ${HIERARCHY_LEVEL_SPACING[level]}
  `
);

const BodyCell = ({
  fieldName,
  value,
  modified,
  tooltip,
  style,
  fields,
  prefix,
  textProps,
  rowData,
  centered,
  hierarchy,
  getPrefix,
}) => (
  <div style={style}>
    <Content centered={centered}>
      {hierarchy && (
        <Hierarchy level={hierarchy?.level}>
          <HierarchyArrow />
        </Hierarchy>
      )}
      <StyledText noMargin size="two" {...textProps}>
        {prefix || getPrefix(rowData, fieldName)}
        {toString(value)}
      </StyledText>
      {modified && <Indicator style={{ marginLeft: 4 }} />}
      {!!keys(fields).length &&
        map(
          (field) => (
            <StyledText
              noMargin
              size="two"
              key={`${fieldName}-${field.fieldName}`}
            >
              {field.prefix}
              {toString(field.value)}
            </StyledText>
          ),
          fields
        )}
    </Content>
    {tooltip && <Tooltip content={tooltip} />}
  </div>
);

BodyCell.defaultProps = {
  fieldName: '',
  value: '',
  tooltip: '',
  modified: false,
  style: {},
  fields: {},
  prefix: '',
  textProps: {},
  rowData: {},
  centered: false,
  hierarchy: null,
  getPrefix: () => '',
};

BodyCell.propTypes = {
  fieldName: PropTypes.string,
  value: PropTypes.any,
  tooltip: PropTypes.string,
  modified: PropTypes.bool,
  style: PropTypes.object,
  fields: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  prefix: PropTypes.any,
  textProps: PropTypes.object,
  rowData: PropTypes.object,
  centered: PropTypes.bool,
  hierarchy: PropTypes.object,
  getPrefix: PropTypes.func,
};

export default BodyCell;
