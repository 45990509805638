import React from 'react';
import { CUSTOM_FIELD_TYPES, EN_GB_LOCALE } from 'variables';
import { formatCurrency } from '@sumup/intl';
import moment from 'moment';
import getPreAuthInfo from '../../services/authorisations';

export const MERCHANT_DETAILS = {
  name: 'details',
  label: 'Details',
  getValue: ({ merchantName }) => merchantName,
};

export const MCC = {
  name: 'mcc',
  label: 'MCC',
  getValue: ({ merchantCode, merchantCodeDescription }) => {
    return { code: merchantCode, description: merchantCodeDescription };
  },
  renderAs: CUSTOM_FIELD_TYPES.MCC,
};

export const MERCHANT_CATEGORY_CODE_DESCRIPTION = {
  name: 'merchantCategoryCodeDescription',
  label: 'Merchant Category Code Description',
  getValue: ({ merchantCategoryCodeDescription }) =>
    merchantCategoryCodeDescription,
};

export const BILLING_AMOUNT = {
  label: 'Amount',
  name: 'billingAmount',
  sortName: 'BILLING_AMOUNT',
  getValue: ({ billing: { amount, currency } }) => {
    const sign = (amount && '-') || '';

    return `${sign}${formatCurrency(amount / 100, EN_GB_LOCALE, currency)}`;
  },
};

export const CLEARING_AMOUNT = {
  label: 'Amount',
  name: 'clearingAmount',
  getValue: ({ cleared: { amount, currency } }) => {
    const sign = (amount && '-') || '';

    return `${sign}${formatCurrency(amount / 100, EN_GB_LOCALE, currency)}`;
  },
};

export const AUTH_IDENTIFIER = {
  label: 'Auth',
  copyable: true,
  cropText: true,
  getValue: ({ authorizationCode }) => authorizationCode,
};

export const CARD_IDENTIFIER = {
  label: 'Card token',
  name: 'cardToken',
  copyable: true,
  cropText: true,
  getValue: ({ cardToken }) => cardToken,
};

export const MID = {
  label: 'MID',
  name: 'MID',
  cropText: true,
  getValue: ({ cardToken, merchantId }) => ({
    details: { cardToken, merchantId },
    type: 'client',
  }),
  renderAs: CUSTOM_FIELD_TYPES.LINK,
};

export const TX_IDENTIFIER = {
  name: 'txId',
  label: 'Token',
  copyable: true,
  cropText: true,
  getValue: ({ authorizationIdentifier }) => authorizationIdentifier,
};

export const RETRIEVAL_REFERENCE_NUMBER = {
  label: 'RRN',
  name: 'retrievalReferenceNumber',
  copyable: true,
  cropText: true,
  getValue: ({ retrievalReferenceNumber }) => retrievalReferenceNumber,
};

export const DATE = {
  name: 'date',
  label: 'Date',
  sortName: 'DATE',
  sortable: true,
  getValue: ({ date }) =>
    date ? moment(date).format('MMM DD, YYYY HH:MM:SS') : '—',
};

export const AMOUNT = {
  name: 'amount',
  label: 'Amount',
  getValue: ({ amountInSourceCurrency: { amount, currency } }) => {
    const sign = (amount && '-') || '';

    return `${sign}${formatCurrency(amount / 100, EN_GB_LOCALE, currency)}`;
  },
};

export const PRE_AUTH = {
  name: 'pre_auth',
  label: 'PreAuth',
  getValue: ({ transactionStatus }) => getPreAuthInfo(transactionStatus),
};

export const FEE = {
  name: 'fee',
  label: 'Fee',
  getValue: ({ fee: { amount, currency } }) =>
    formatCurrency(amount / 100, EN_GB_LOCALE, currency),
};

export const CANCEL_BUTTON = {
  label: ' ',
  name: 'cancelButton',
  renderAs: CUSTOM_FIELD_TYPES.CANCEL_BUTTON,
  getValue: ({ authorizationCode, cardToken, merchantId }) => ({
    authorizationCode,
    cardToken,
    merchantId,
  }),
};

export const REDIRECT_BUTTON = {
  label: ' ',
  name: 'redirectButton',
  renderAs: CUSTOM_FIELD_TYPES.REDIRECT_BUTTON,
  getValue: ({ authorizationCode, cardToken, merchantId }) => ({
    authorizationCode,
    cardToken,
    merchantId,
  }),
};

const billingAmountColumns = [
  {
    key: BILLING_AMOUNT.name,
    fields: [
      {
        ...BILLING_AMOUNT,
        fullLabel: BILLING_AMOUNT.label,
        sortable: true,
      },
    ],
  },
];

const clearingAmountColumns = [
  {
    key: CLEARING_AMOUNT.name,
    fields: [
      {
        ...CLEARING_AMOUNT,
        fullLabel: CLEARING_AMOUNT.label,
      },
    ],
  },
];

const authorizationColumns = [
  {
    key: DATE.name,
    fields: [
      {
        ...DATE,
      },
    ],
    style: {
      width: '15%',
    },
  },
  {
    key: CARD_IDENTIFIER.name,
    fields: [
      {
        ...MID,
        fullLabel: MID.name,
      },
      {
        ...CARD_IDENTIFIER,
        prefix: <span>C:</span>,
        fullLabel: CARD_IDENTIFIER.label,
      },
    ],
    style: {
      width: '30%',
    },
  },
  {
    key: 'txDetails',
    fields: [
      {
        ...RETRIEVAL_REFERENCE_NUMBER,
        prefix: <span>R:</span>,
        fullLabel: RETRIEVAL_REFERENCE_NUMBER.label,
      },
      {
        ...AUTH_IDENTIFIER,
        prefix: <span>A:</span>,
        fullLabel: AUTH_IDENTIFIER.label,
      },
    ],
    style: {
      width: '25%',
    },
  },
  {
    key: PRE_AUTH.name,
    fields: [
      {
        ...PRE_AUTH,
      },
    ],
    style: {
      width: '5%',
    },
  },
  {
    key: AMOUNT.name,
    fields: [
      {
        ...AMOUNT,
      },
    ],
    style: {
      width: '15%',
    },
  },
  {
    key: FEE.name,
    fields: [
      {
        ...FEE,
      },
    ],
    style: {
      width: '10%',
    },
  },
];

const buttonsColumns = [
  {
    key: CANCEL_BUTTON.name,
    fields: [
      {
        ...CANCEL_BUTTON,
        fullLabel: CANCEL_BUTTON.label,
      },
    ],
  },
  {
    key: REDIRECT_BUTTON.name,
    fields: [
      {
        ...REDIRECT_BUTTON,
        fullLabel: REDIRECT_BUTTON.label,
      },
    ],
  },
];

const merchantColumns = [
  {
    key: MCC.name,
    fields: [
      {
        ...MCC,
      },
    ],
    style: {
      width: '30%',
    },
  },
  {
    key: MERCHANT_DETAILS.name,
    fields: [
      {
        ...MERCHANT_DETAILS,
      },
    ],
    style: {
      width: '70%',
    },
  },
];

export const columns = [
  {
    label: 'Authorization',
    style: {
      width: '55%',
    },
    subColumns: authorizationColumns,
  },
  {
    label: 'Billing',
    style: {
      width: '8%',
    },
    subColumns: billingAmountColumns,
  },
  {
    label: 'Clearing',
    style: {
      width: '8%',
    },
    subColumns: clearingAmountColumns,
  },
  {
    label: 'Merchant',
    style: {
      width: '20%',
    },
    subColumns: merchantColumns,
  },
  {
    label: '',
    style: {
      width: '25%',
    },
    subColumns: buttonsColumns,
  },
];
