import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Body, spacing } from '@sumup/circuit-ui';
import { Info } from '@sumup/icons';
import Empty from 'components/Empty';
import { formatBankLimit } from 'components/Limits/service';
import Tooltip from 'components/Tooltip';
import PropTypes from 'prop-types';
import React from 'react';
import { LimitTooltipContent } from './LimitTooltipContent';

const Grid = styled('div')(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    background: ${theme.colors.white};
    filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.1));
    border-radius: ${theme.borderRadius.bit};
    margin-top: 20px;
  `
);

const Row = styled('div')(
  ({ theme, directDebitsEnabled = true, isHeader = false }) => css`
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;

    :first-of-type {
      border-bottom: ${theme.borderWidth.mega} solid ${theme.colors.n200};
    }

    :nth-of-type(2) {
      border-bottom: ${theme.borderWidth.kilo} solid ${theme.colors.n200};
    }

    > div {
      display: flex;
      align-items: center;
      padding: 0px ${theme.spacings.mega};
      height: 68px;

      :first-of-type {
        width: 16%;
      }

      ${directDebitsEnabled
        ? css`
            :nth-of-type(2) {
              width: 20%;
            }

            :nth-of-type(3) {
              width: 33%;
            }
          `
        : css`
            :nth-of-type(2) {
              width: 81%;
              justify-content: center;
            }
          `}

      :not(:last-of-type) {
        border-right: 1px solid ${theme.colors.n200};
      }
    }

    ${!isHeader &&
    css`
      :hover {
        background: ${theme.colors.b100};
      }
    `}
  `
);

const HeaderRow = styled(Row)(
  ({ theme }) => css`
    max-height: 42px;
    color: ${theme.colors.n700};

    > div {
      max-height: 42px;
    }
  `
);

const StyledTooltip = styled(Tooltip)`
  > div {
    min-width: 216px;
  }
`;

const StyledBody = styled(Body)(({ theme, flagged }) =>
  flagged
    ? css`
        background: ${theme.colors.alert};
        color: ${theme.colors.white};
        border-radius: ${theme.borderRadius.kilo};
        padding: 0 ${theme.spacings.byte};
      `
    : ''
);

const limitTypeDaily = 'daily';
const limitTypeMonthly = 'monthly';

const BankLimits = ({ limits, currency, directDebitsEnabled }) => {
  if (!limits) {
    return null;
  }

  const {
    transaction_limit: transactionLimit,
    daily_limit: dailyLimit,
    daily_limit_used: dailyLimitUsed,
    monthly_limit: monthlyLimit,
    monthly_limit_used: monthlyLimitUsed,
    dd_transaction_limit: ddTransactionLimit,
    dd_monthly_limit: ddMonthlyLimit,
    dd_monthly_limit_used: ddMonthlyLimitUsed,
  } = limits;

  const formattedDailyLimit = formatBankLimit(dailyLimit, currency);
  const formattedDailyLimitUsed = formatBankLimit(dailyLimitUsed, currency);
  const dailyLimitRemaining = dailyLimit - dailyLimitUsed;
  const formattedDailyLimitRemaining = formatBankLimit(
    dailyLimitRemaining,
    currency
  );
  const formattedMonthlyLimit = formatBankLimit(monthlyLimit, currency);
  const formattedMonthlyLimitUsed = formatBankLimit(monthlyLimitUsed, currency);
  const monthlyLimitRemaining = monthlyLimit - monthlyLimitUsed;
  const formattedMonthlyLimitRemaining = formatBankLimit(
    monthlyLimitRemaining,
    currency
  );
  const formattedDdMonthlyLimit = formatBankLimit(ddMonthlyLimit, currency);
  const formattedDdMonthlyLimitUsed = formatBankLimit(
    ddMonthlyLimitUsed,
    currency
  );
  const ddMonthlyLimitRemaining = ddMonthlyLimit - ddMonthlyLimitUsed;
  const formattedDdMonthlyLimitRemaining = formatBankLimit(
    ddMonthlyLimitRemaining,
    currency
  );

  return (
    <Grid>
      <HeaderRow isHeader>
        <div />
        <div>
          <Body noMargin size="two" variant="highlight">
            Single amount
          </Body>
        </div>
        <div>
          <Body noMargin size="two" variant="highlight">
            Daily amount
          </Body>
        </div>
        <div>
          <Body noMargin size="two" variant="highlight">
            Monthly amount
          </Body>
        </div>
      </HeaderRow>
      <Row>
        <div>
          <Body noMargin size="two">
            Transfer out
          </Body>
        </div>
        <div>
          <Body noMargin size="one" variant="highlight">
            {formatBankLimit(transactionLimit, currency)}
          </Body>
        </div>
        <div>
          <StyledTooltip
            position="top"
            pivot={
              <StyledBody
                noMargin
                size="one"
                variant="highlight"
                flagged={dailyLimit === dailyLimitUsed}
              >
                {formattedDailyLimitUsed}/{formattedDailyLimit}
              </StyledBody>
            }
            content={
              <LimitTooltipContent
                limitType={limitTypeDaily}
                remainingAmountNumber={dailyLimitRemaining}
                remainingAmount={formattedDailyLimitRemaining}
                usedAmount={formattedDailyLimitUsed}
                totalAmount={formattedDailyLimit}
              />
            }
          />
        </div>
        <div>
          <StyledTooltip
            position="top"
            pivot={
              <StyledBody
                noMargin
                size="one"
                variant="highlight"
                flagged={monthlyLimit === monthlyLimitUsed}
              >
                {formattedMonthlyLimitUsed}/{formattedMonthlyLimit}
              </StyledBody>
            }
            content={
              <LimitTooltipContent
                limitType={limitTypeMonthly}
                remainingAmountNumber={monthlyLimitRemaining}
                remainingAmount={formattedMonthlyLimitRemaining}
                usedAmount={formattedMonthlyLimitUsed}
                totalAmount={formattedMonthlyLimit}
              />
            }
          />
        </div>
      </Row>
      <Row directDebitsEnabled={directDebitsEnabled}>
        <div>
          <Body noMargin size="two">
            Direct debits
          </Body>
        </div>
        {directDebitsEnabled ? (
          <>
            <div>
              <Body noMargin size="one" variant="highlight">
                {formatBankLimit(ddTransactionLimit, currency)}
              </Body>
            </div>
            <div>
              <Empty>—</Empty>
            </div>
            <div>
              <StyledTooltip
                position="top"
                pivot={
                  <StyledBody
                    noMargin
                    size="one"
                    variant="highlight"
                    flagged={ddMonthlyLimit === ddMonthlyLimitUsed}
                  >
                    {formattedDdMonthlyLimitUsed}/{formattedDdMonthlyLimit}
                  </StyledBody>
                }
                content={
                  <LimitTooltipContent
                    limitType={limitTypeMonthly}
                    remainingAmountNumber={ddMonthlyLimitRemaining}
                    remainingAmount={formattedDdMonthlyLimitRemaining}
                    usedAmount={formattedDdMonthlyLimitUsed}
                    totalAmount={formattedDdMonthlyLimit}
                  />
                }
              />
            </div>
          </>
        ) : (
          <div>
            <Info size="16" />
            <Body css={spacing({ left: 'byte' })} noMargin>
              Merchant has their debits disabled.
            </Body>
          </div>
        )}
      </Row>
    </Grid>
  );
};

BankLimits.defaultProps = {
  limits: null,
  directDebitsEnabled: true,
};

BankLimits.propTypes = {
  limits: PropTypes.object,
  currency: PropTypes.string.isRequired,
  directDebitsEnabled: PropTypes.bool,
};

export default BankLimits;
