import styled from '@emotion/styled';
import {
  Headline,
  Card,
  Body,
  RadioButtonGroup,
  Button,
  SelectorGroup,
  NotificationInline,
  Popover,
} from '@sumup/circuit-ui';
import { css } from '@emotion/react';
import { CARD_TYPES } from 'variables';
import Status from 'components/Status';
import Tooltip from 'components/Tooltip';

export const DataGridSplit = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  & > div {
    width: calc(65% - 10px);
    & + div {
      width: calc(35% - 10px);
    }
  }
`;

export const StyledHeading = styled(Headline)`
  display: flex;
  margin-bottom: 20px;
  font-size: 28px;
  line-height: 32px;
`;

export const Block25 = styled('div')(
  ({ theme }) => css`
    display: flex;
    width: calc(50% - 10px);
    flex-direction: column;
    flex-wrap: nowrap;

    ${theme.mq.giga} {
      width: calc(25% - 10px);
    }
  `
);

export const Block50 = styled('div')(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    width: 100%;
    margin-bottom: ${theme.spacings.giga};
    ${theme.mq.giga} {
      width: calc(50% - 10px);
      margin-bottom: 0;
    }
  `
);

export const Row = styled('div')(
  ({ theme }) => css`
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: wrap;
    margin-bottom: ${theme.spacings.giga};
  `
);

export const DividedBlock = styled('div')(
  ({ theme, spread }) => css`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    ${!spread && `justify-content: flex-start;`}
    padding: ${theme.spacings.kilo} 20px;
    ${theme.mq.tera} {
      padding: ${theme.spacings.kilo} ${theme.spacings.giga};
    }
    & > p {
      display: flex;
      flex-basis: 100%;
    }
    & > div {
      ${spread && `flex: 1;`}

      &:not(:last-of-type) {
        margin-right: ${theme.spacings.mega};
      }
    }
  `
);

export const CardHeadingBlock = styled('div')(
  ({ theme, primary }) => css`
    display: flex;
    flex-direction: column;
    padding: ${theme.spacings.kilo} 20px;
    ${primary &&
    `
      flex-direction: row;
      flex-wrap: wrap;`}

    & > div > div:not(:last-of-type) {
      margin-right: ${theme.spacings.giga};
    }
  `
);

export const DividedBlockRow = styled('div')(
  ({ primary }) => css`
    display: flex;
    ${primary && `flex-basis: 100%`}
  `
);

export const DividedBlockColumn = styled('div')`
  display: flex;
  flex-direction: column;
`;

export const StyledLabel = styled(Body)(
  ({ theme }) => css`
    color: ${theme.colors.n700};
    font-size: 12px;
  `
);

export const StyledText = styled('p')(
  ({ theme, cardType }) => css`
    text-transform: none;
    font-size: ${theme.typography.body.two.fontSize};
    line-height: ${theme.typography.body.two.lineHeight};
    font-weight: ${theme.fontWeight.bold};
    color: ${(cardType === CARD_TYPES.physical && theme.colors.p500) ||
    (cardType === CARD_TYPES.virtual && '#6999FA') ||
    'inherit'};
  `
);

export const StyledCard = styled(Card)`
  padding: 0;
`;

export const CardHeader = styled('div')(
  ({ theme, type }) => css`
    background: ${type === CARD_TYPES.physical ? '#E0EEFF' : theme.colors.p200};
    position: relative;
    border-radius: ${theme.borderRadius.kilo} ${theme.borderRadius.kilo} 0 0;
    display: flex;

    img {
      max-height: 98px;
      position: relative;
      margin-top: -6px;
      margin-left: 20px;
    }
  `
);

export const CardFooter = styled('div')(
  ({ theme }) => css`
    border-top: 1px solid ${theme.colors.n200};
    padding: ${theme.spacings.kilo} 20px;
    display: flex;
    ${theme.mq.tera} {
      padding: ${theme.spacings.giga};
    }
    button {
      & + div {
        margin-left: auto;
      }
    }
    > div {
      margin-left: auto;
    }
  `
);

export const Split = styled('div')`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  flex-basis: 100%;
  justify-content: space-between;
`;

export const CardStatusPopoverComponent = styled('div')(
  ({ theme, disabled }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: ${theme.colors.n100};
    border-radius: ${theme.borderRadius.bit};
    padding: ${theme.spacings.byte};
    cursor: pointer;
    & > div {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    svg {
      color: ${theme.colors.n500};
    }
    ${disabled &&
    `
      background: ${theme.colors.white};
      border: ${theme.borderWidth.kilo} solid ${theme.colors.n200};
      pointer-events: none;
    `}
  `
);

export const Inactive = styled('div')(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    background: ${theme.colors.n200};
    color: ${theme.colors.n500};
    border-radius: ${theme.borderRadius.bit};
    padding: ${theme.spacings.mega};
    width: 100%;
    height: 64px;
  `
);

export const Balance = styled(Card)(
  ({ theme, negativeBalance, nonNegativeBalance }) => css`
    padding: ${theme.spacings.kilo} ${theme.spacings.mega};
    background: ${(negativeBalance && '#F9D2D4') ||
    (nonNegativeBalance && '#BCF6D7') ||
    theme.colors.white};

    > p:not(:first-of-type) {
      color: ${(negativeBalance && theme.colors.r500) ||
      (nonNegativeBalance && theme.colors.g700) ||
      theme.colors.black};
    }

    > span {
      width: 20px;
      height: 20px;
    }
  `
);

export const GrayedOutSplit = styled('div')(
  ({ theme, disabled }) => css`
    background: ${theme.colors.n100};
    border-radius: ${theme.borderRadius.bit};
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-height: 40px;

    ${disabled &&
    `
      background: ${theme.colors.white};
      border: ${theme.borderWidth.kilo} solid ${theme.colors.n200};
      label, button {
        pointer-events: none;
      }
    `}

    button {
      display: flex;
      align-items: center;
      max-height: 40px;
      border-radius: 0;
      border-left: ${theme.borderWidth.kilo} solid ${theme.colors.n200};
    }

    & > div,
    p,
    & > button {
      padding: ${theme.spacings.byte};
      text-transform: capitalize;
    }
  `
);

export const StyledStatus = styled(Status)(
  ({ theme }) => css`
    display: flex;
    margin: ${theme.spacings.kilo} 0;
  `
);

export const UpdateTokenModalContent = styled('div')(
  ({ theme: { spacings } }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;

    > div {
      display: flex;
    }

    > p {
      margin-bottom: ${spacings.byte};
    }
  `
);

export const ColoredText = styled(Body)(
  ({ theme, active }) => css`
    color: ${active ? theme.colors.g700 : theme.colors.n700};
    ${active && `font-weight: bold;`}
  `
);

export const CustomAddress = styled('form')(
  ({ theme }) => css`
    display: flex;
    flex-wrap: wrap;
    margin-top: ${theme.spacings.byte};
    & > label {
      width: 100%;
      margin-bottom: ${theme.spacings.bit};
      input {
        width: 100%;
      }
      &:nth-of-type(3) {
        width: calc(60% - (${theme.spacings.bit} / 2));
        margin-right: calc(${theme.spacings.bit} / 2);
      }
      &:nth-of-type(4) {
        width: calc(40% - (${theme.spacings.bit} / 2));
        margin-left: calc(${theme.spacings.bit} / 2);
      }
    }
  `
);

export const StyledRadioButtonGroup = styled(RadioButtonGroup)(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    margin-top: ${theme.spacings.byte};

    > div:first-of-type {
      margin-bottom: ${theme.spacings.byte};
    }
  `
);

export const IssueCardForm = styled('div')(
  ({ theme }) => css`
    width: 100%;
    flex-direction: column;
    & > * {
      justify-content: flex-start;
      width: 100%;
    }

    > h3 {
      display: flex;
      margin-top: ${theme.spacings.giga};
    }

    span {
      display: flex;
      justify-content: flex-start;
    }
  `
);

export const CountryIcon = styled('div')(
  ({ theme }) => css`
    background: ${theme.colors.n100};
    padding: ${theme.spacings.byte} ${theme.spacings.kilo};
    display: flex;
    align-items: center;
    border-radius: ${theme.borderRadius.giga};
    svg {
      width: ${theme.spacings.mega};
      height: ${theme.spacings.mega};
      margin-right: ${theme.spacings.byte};
    }
  `
);

export const LockCardAction = styled('div')(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    color: ${theme.colors.alert};
    cursor: pointer;

    svg {
      margin-right: ${theme.spacings.byte};
    }
  `
);

export const ViewShippingInfoAction = styled('div')(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    color: ${theme.colors.black};
    cursor: pointer;

    svg {
      margin-right: ${theme.spacings.byte};
    }
  `
);

export const LockedForAuditBanner = styled('div')(
  ({ theme }) => css`
    display: flex;
    justify-content: center;
    background: ${theme.colors.alert};
    color: ${theme.colors.white};
  `
);

export const ExpiringSoonBanner = styled('div')(
  ({ theme }) => css`
    display: flex;
    justify-content: center;
    background: ${theme.colors.y100};
    color: ${theme.colors.black};
  `
);

export const AutoReplacedBanner = styled('div')(
  ({ theme }) => css`
    background: ${theme.colors.n200};
    text-align: center;
    p {
      display: inline;
    }
  `
);

export const LockCardModalContent = styled('div')(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;

    & > p {
      margin-bottom: ${theme.spacings.kilo};
    }
  `
);

export const SpinnerWrapper = styled('div')`
  display: flex;
  margin-left: auto;
  margin-right: 20px;
  align-items: center;
`;

export const StyledLoadingButton = styled(Button)`
  display: flex;
  align-items: center;
  max-height: 36px;
`;

export const BalanceHeading = styled('div')`
  display: flex;
  justify-content: space-between;
`;

export const StyledIssueCardTooltip = styled(Tooltip)`
  > div {
    min-width: 320px;
  }
`;

export const StyledSelectorGroup = styled(SelectorGroup)(
  ({ theme }) => css`
    width: 100%;
    & > div {
      color: ${theme.colors.p500};
    }
  `
);

export const StyledNotificationInline = styled(NotificationInline)`
  width: 100%;
`;

export const CardReplaceContent = styled.div(
  ({ theme }) => css`
    text-align: left;
    margin-bottom: ${theme.spacings.kilo};
    label {
      margin-bottom: ${theme.spacings.kilo};
    }
  `
);

export const CircuitPopoverStyled = styled(Popover)`
  button:empty {
    display: none;
  }
`;
