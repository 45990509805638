import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { CUSTOM_FIELD_TYPES, URLS, PATHS } from 'variables';
import { buildQueryParams } from 'services/common';
import { Button } from '@sumup/circuit-ui';
import CopyableCell from 'components/CopyableCell';
import { ReactComponent as LinkIcon } from 'assets/link.svg';
import MerchantCategoryCell from 'components/MerchantCategoryCode';
import { useHttpClient } from 'hooks';
import { StyledEmptyCell } from './cellStyled';
import Link from '../../../../AdjustAuthorizations/components/AuthorizationsGrid/components/Link';

export const CellFactory = ({ renderAs, value, rowData, ...props }) => {
  const httpClient = useHttpClient();
  const [cancelLoading, setCancelLoading] = useState(false);
  const [cancelError, setCancelError] = useState(false);
  const [cancelSuccess, setCancelSuccess] = useState(false);

  const handleRedirect = ({ merchantId, authorizationCode }) => {
    window.open(
      `${window.location.origin}/client/${merchantId}/transactions?auth_code=${authorizationCode}`
    );
  };

  const handleCancelAuthorization = async ({
    authorizationCode,
    merchantId,
  }) => {
    setCancelLoading(true);

    const { data } = await httpClient.get(
      `${URLS.clientsV1}/${merchantId}/${PATHS.transactions}${buildQueryParams([
        { param: 'authentication_code', value: authorizationCode },
      ])}`
    );
    const { identifier } =
      data?.transactions?.length > 0 && data.transactions[0];

    httpClient
      .post(
        `${URLS.clients}/${merchantId}/${PATHS.transactions}/${identifier}/cancel`
      )
      .then(() => {
        setCancelSuccess(true);
        setCancelLoading(false);
      })
      .catch(() => {
        setCancelError(true);
        setCancelLoading(false);
      });
  };

  if (value == null) {
    return <StyledEmptyCell>—</StyledEmptyCell>;
  }

  let render = renderAs;
  if (typeof renderAs === 'function') {
    render = renderAs({ value, record: rowData });
  }

  switch (render) {
    case CUSTOM_FIELD_TYPES.LINK:
      return <Link {...props} value={value} />;
    case CUSTOM_FIELD_TYPES.CANCEL_BUTTON:
      return (
        !cancelSuccess && (
          <Button
            destructive
            variant="primary"
            size="kilo"
            disabled={cancelError}
            onClick={() => handleCancelAuthorization(value)}
            isLoading={cancelLoading}
            loadingLabel="Loading"
          >
            {cancelError ? 'Error' : 'Cancel'}
          </Button>
        )
      );
    case CUSTOM_FIELD_TYPES.REDIRECT_BUTTON:
      return (
        <Button
          icon={LinkIcon}
          destructive
          variant="primary"
          size="kilo"
          onClick={() => handleRedirect(value)}
        >
          Transaction
        </Button>
      );
    case CUSTOM_FIELD_TYPES.MCC:
      return (
        <MerchantCategoryCell
          mcc={value.code}
          mccDescription={value.description}
        />
      );
    default:
      return <CopyableCell {...props} value={value} centered />;
  }
};

CellFactory.defaultProps = {
  renderAs: '',
  value: '',
  rowData: {},
};

CellFactory.propTypes = {
  renderAs: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
    PropTypes.object,
  ]),
  rowData: PropTypes.object,
};
