import { actionTypes } from 'variables';
import { orderCardLimits } from './service';

export const limitsReducer = (state, action) => {
  switch (action.type) {
    case actionTypes.SET_LOADING: {
      const {
        payload: { loading },
      } = action;

      return {
        ...state,
        loading,
      };
    }
    case actionTypes.LOAD_SUCCESS: {
      const {
        payload: { cardLimits, data, ...payload },
      } = action;

      const {
        can_create_bank_account: canCreateBankAccount,
        country,
        currency,
      } = data || {};

      return {
        ...state,
        loading: false,
        cardLimits: orderCardLimits(cardLimits),
        canCreateBankAccount,
        country,
        currency,
        ...payload,
      };
    }
    case actionTypes.SET_CARD_LIMITS: {
      const {
        payload: { cardLimits },
      } = action;

      return {
        ...state,
        loading: false,
        cardLimits: orderCardLimits(cardLimits),
      };
    }
    default:
      return state;
  }
};
