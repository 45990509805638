import { actionTypes } from 'variables';

export const setCardLimits = (cardLimits) => ({
  type: actionTypes.SET_CARD_LIMITS,
  payload: {
    cardLimits,
  },
});

export const setLoading = (loading) => ({
  type: actionTypes.SET_LOADING,
  payload: { loading },
});
