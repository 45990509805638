import React from 'react';
import PropTypes from 'prop-types';
import { split, flow, toUpper, join, capitalize } from 'lodash/fp';
import styled from '@emotion/styled';
import {
  CUSTOM_FIELD_TYPES,
  FIELD_TYPES,
  TOOLTIPS,
  CARD_STATUSES_MAPPED,
} from 'variables';
import Empty from 'components/Empty';
import Status from 'components/Status';
import BodyCell from '../components/BodyCell';
import ButtonLinkCell from '../components/ButtonLinkCell';
import DateCell from '../components/DateCell';
import ButtonCell from '../components/ButtonCell';
import InputCell from '../components/InputCell';
import { FloatCell } from '../components/NumberCell';
import AmountCell from '../components/AmountCell';
import WalletCell from '../components/WalletCell';
import PopoverCell from '../components/PopoverCell';
import CompositeAmountCell from '../components/CompositeAmountCell';
import DeleteScaDevice from '../components/DeleteScaDevice';
import StatusBadgeCell from '../components/StatusBadgeCell';
import { StyledText } from '../../Account/AccountStyled';

const EmptyCell = styled('div')(
  ({ centered }) =>
    centered &&
    `
  display: flex;
  justify-content: center;
`
);

export const CellFactory = ({
  renderAs,
  onInputChange,
  value,
  invalid,
  clientId,
  activeCard,
  rowData,
  disabled,
  hasActionAccess,
  permissions,
  paymentsBlocked,
  showHierarchy,
  onLinkClick,
  onDeleteDevice,
  ...props
}) => {
  if (value === null) {
    return (
      <EmptyCell {...props}>
        <Empty>—</Empty>
      </EmptyCell>
    );
  }

  let render = renderAs;
  if (typeof renderAs === 'function') {
    render = renderAs({
      value,
      record: rowData,
      hasActionAccess: hasActionAccess(permissions),
      paymentsBlocked,
    });
  }

  switch (render) {
    case CUSTOM_FIELD_TYPES.BUTTON_LINK:
      return (
        <ButtonLinkCell
          {...props}
          value={value}
          rowData={rowData}
          onClick={onLinkClick}
        />
      );
    case CUSTOM_FIELD_TYPES.BUTTON_WARNING:
      return hasActionAccess(permissions) || !permissions ? (
        <ButtonCell
          {...props}
          type={render}
          value={value}
          clientId={clientId}
          activeCard={activeCard}
          rowData={rowData}
        />
      ) : (
        <div />
      );
    case CUSTOM_FIELD_TYPES.INPUT:
      return (
        <InputCell
          {...props}
          value={value}
          invalid={invalid}
          disabled={disabled}
          onInputChange={onInputChange}
        />
      );
    case FIELD_TYPES.DATE_TIME:
      return <DateCell {...props} value={value} />;
    case FIELD_TYPES.FLOAT:
      return <FloatCell {...props} value={value} />;
    case CUSTOM_FIELD_TYPES.AMOUNT:
      return <AmountCell {...props} value={value} rowData={rowData} />;
    case CUSTOM_FIELD_TYPES.COMPOSITE_AMOUNT:
      return <CompositeAmountCell {...props} value={value} />;
    case CUSTOM_FIELD_TYPES.STATUS:
      return <Status {...props} value={CARD_STATUSES_MAPPED[value] || value} />;
    case CUSTOM_FIELD_TYPES.CARD_TYPE:
      return (
        <div>
          <StyledText noMargin cardType={value}>
            {capitalize(value)}
          </StyledText>
        </div>
      );
    case CUSTOM_FIELD_TYPES.WALLET:
      return <WalletCell {...props} value={value} />;
    case CUSTOM_FIELD_TYPES.POPOVER:
      return (
        <PopoverCell
          {...props}
          value={value}
          clientId={clientId}
          activeCard={activeCard}
          rowData={rowData}
        />
      );
    case CUSTOM_FIELD_TYPES.EMPTY:
      return <div />;
    case CUSTOM_FIELD_TYPES.DELETE_SCA_BUTTON:
      return (
        <DeleteScaDevice
          {...props}
          value={value}
          clientId={clientId}
          onDeleteDevice={onDeleteDevice}
          mfaEnabled={rowData?.mfa_enabled}
          mfaSessionExists={rowData?.mfa_session_exists}
        />
      );
    case CUSTOM_FIELD_TYPES.STATUS_BADGE:
      return <StatusBadgeCell {...props} value={value} rowData={rowData} />;
    case CUSTOM_FIELD_TYPES.CELL:
      return value;
    default:
      return (
        <BodyCell
          {...props}
          value={value}
          tooltip={TOOLTIPS[flow(toUpper, split(' '), join('_'))(value)]}
          hierarchy={showHierarchy ? rowData?.hierarchy : undefined}
        />
      );
  }
};

CellFactory.defaultProps = {
  renderAs: '',
  value: '',
  invalid: false,
  clientId: '',
  activeCard: {},
  rowData: {},
  disabled: false,
  permissions: null,
  showHierarchy: false,
  onInputChange() {},
  hasActionAccess: () => true,
  onLinkClick: () => {},
  onDeleteDevice: () => {},
};

CellFactory.propTypes = {
  renderAs: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
    PropTypes.node,
    PropTypes.object,
  ]),
  invalid: PropTypes.bool,
  clientId: PropTypes.string,
  activeCard: PropTypes.object,
  rowData: PropTypes.object,
  onInputChange: PropTypes.func,
  disabled: PropTypes.bool,
  permissions: PropTypes.shape({
    app: PropTypes.string,
    action: PropTypes.string,
  }),
  paymentsBlocked: PropTypes.bool.isRequired,
  showHierarchy: PropTypes.bool,
  hasActionAccess: PropTypes.func,
  onLinkClick: PropTypes.func,
  onDeleteDevice: PropTypes.func,
};
