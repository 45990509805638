import React from 'react';
import PropTypes from 'prop-types';
import { Body, Headline } from '@sumup/circuit-ui';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { LIMIT_TOOLTIP_CONTENT_TEST_ID } from 'components/Limits/constants';
import { Notify } from '@sumup/icons';

const Wrapper = styled('div')(
  ({ theme }) => css`
    > div:first-of-type {
      margin-bottom: ${theme.spacings.mega};
    }
  `
);

const Row = styled('div')(
  ({ theme, fullWidth }) => css`
    display: flex;
    flex-direction: row;

    h2 {
      color: ${theme.colors.white};
    }

    > div {
      display: flex;
      flex-direction: column;
      width: ${fullWidth ? '100%' : '50%'};
    }
  `
);

const StyledHeadline = styled(Headline)(
  ({ theme }) => css`
    display: flex;
    align-items: center;

    svg {
      color: ${theme.colors.alert};
    }
  `
);
export const LimitTooltipContent = ({
  limitType,
  remainingAmountNumber,
  remainingAmount,
  usedAmount,
  totalAmount,
}) => (
  <Wrapper data-testid={LIMIT_TOOLTIP_CONTENT_TEST_ID}>
    <Row fullWidth>
      <div>
        <Body noMargin size="two" variant="subtle">
          Remaining {limitType} limit:
        </Body>
        <StyledHeadline noMargin size="four" variant="highlight">
          {remainingAmount}
          {remainingAmountNumber === 0 && <Notify size="16" />}
        </StyledHeadline>
      </div>
    </Row>
    <Row>
      <div>
        <Body noMargin size="two" variant="subtle">
          Used:
        </Body>
        <Body noMargin size="one" variant="highlight">
          {usedAmount}
        </Body>
      </div>
      <div>
        <Body noMargin size="two" variant="subtle">
          Total:
        </Body>
        <Body noMargin size="one" variant="highlight">
          {totalAmount}
        </Body>
      </div>
    </Row>
  </Wrapper>
);

LimitTooltipContent.propTypes = {
  limitType: PropTypes.string.isRequired,
  remainingAmountNumber: PropTypes.number.isRequired,
  remainingAmount: PropTypes.string.isRequired,
  usedAmount: PropTypes.string.isRequired,
  totalAmount: PropTypes.string.isRequired,
};
