import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { map } from 'lodash/fp';
import { getFieldsValues, getSortOrder } from 'services/dataGrid';
import EmptyState from 'components/EmptyState';
import { StyledBodyRow, StyledGrid, StyledHeaderRow } from './DataGridStyled';
import { CellFactory } from './factories/cell';
import HeaderCell from './components/HeaderCell';

const DataGrid = ({
  columns,
  dataSource,
  sort,
  onSort,
  showModified,
  errorsVisible,
  onDisplayErrors,
  checkRowDisabled,
  clientId,
  paymentId,
  activeCard,
  disabled,
  paymentsBlocked,
  noResultsText,
  centered,
  hasActionAccess,
  modalConfirmSuccess,
  mandateHistoryLoadingForId,
  scheduleHistoryLoadingForId,
  country,
  reloadScheduledPayments,
  reloadMandates,
  onLinkClick,
  onDeleteDevice,
  onViewScheduleDetailsClick,
  onViewMandateDetailsClick,
}) => {
  const [data, setData] = useState(dataSource);

  useEffect(() => {
    setData(dataSource);
  }, [dataSource]);

  const handleDataChange = ({
    е: {
      target: { value },
    },
    rowIndex,
    name,
    record,
  }) => {
    const field = record;
    const re = /^[0-9\b]+$/;
    if (value === '' || re.test(value)) {
      const newData = [...data];
      newData[rowIndex][name] = Number.isNaN(parseInt(value, 10))
        ? ''
        : parseInt(value, 10);
      setData(newData);
      field.modified = true;
      if (errorsVisible) {
        onDisplayErrors();
      }
    }
    return field;
  };

  return (
    <StyledGrid>
      <StyledHeaderRow centered={centered}>
        {map(
          ({ name, sortable, headerBorder, ...column }) => (
            <HeaderCell
              key={name}
              {...column}
              name={name}
              border={headerBorder}
              sort={sort}
              sortable={sortable}
              onSort={() =>
                sortable &&
                onSort({
                  ...column,
                  name,
                  order: getSortOrder(sort[name]),
                })
              }
            />
          ),
          columns
        )}
      </StyledHeaderRow>
      {(data || []).length ? (
        data.map((record, rowIndex) => {
          const modified = showModified && record.modified;
          return (
            <StyledBodyRow
              modified={modified}
              key={rowIndex}
              disabled={checkRowDisabled({ record })}
              error={record?.error}
              centered={centered}
            >
              {columns.map(
                (
                  {
                    name,
                    fields,
                    getValue,
                    style,
                    contentCellStyle,
                    ...columnProps
                  },
                  colIndex
                ) => (
                  <CellFactory
                    key={`${rowIndex}-${colIndex}`}
                    value={
                      getValue
                        ? getValue(record, name, {
                            clientId,
                            paymentId,
                            mandateHistoryLoadingForId,
                            scheduleHistoryLoadingForId,
                            reloadScheduledPayments,
                            reloadMandates,
                            country,
                            onViewScheduleDetailsClick,
                            onViewMandateDetailsClick,
                          })
                        : record[name]
                    }
                    rowData={record}
                    fieldName={name}
                    invalid={errorsVisible && record[name] === ''}
                    row={rowIndex}
                    onInputChange={(е) =>
                      handleDataChange({ е, rowIndex, name, record })
                    }
                    fields={getFieldsValues({ fields, record })}
                    modified={modified}
                    clientId={clientId}
                    activeCard={activeCard}
                    disabled={disabled}
                    paymentsBlocked={paymentsBlocked}
                    hasActionAccess={hasActionAccess}
                    modalConfirmSuccess={modalConfirmSuccess}
                    style={{
                      ...style,
                      ...contentCellStyle,
                    }}
                    onLinkClick={onLinkClick}
                    onDeleteDevice={onDeleteDevice}
                    {...columnProps}
                  />
                )
              )}
            </StyledBodyRow>
          );
        })
      ) : (
        <EmptyState text={noResultsText} />
      )}
    </StyledGrid>
  );
};

DataGrid.defaultProps = {
  sort: {},
  disabled: false,
  nonNumericFields: {},
  showModified: false,
  errorsVisible: false,
  activeCard: {},
  noResultsText: 'No results yet',
  paymentsBlocked: false,
  clientId: null,
  paymentId: null,
  centered: false,
  mandateHistoryLoadingForId: null,
  scheduleHistoryLoadingForId: null,
  country: null,
  onDisplayErrors: () => {},
  checkRowDisabled: () => false,
  onSort: () => {},
  hasActionAccess: () => {},
  modalConfirmSuccess: () => {},
  onLinkClick: () => {},
  onDeleteDevice: () => {},
  reloadScheduledPayments: () => {},
  reloadMandates: () => {},
  onViewScheduleDetailsClick: () => {},
  onViewMandateDetailsClick: () => {},
};

DataGrid.propTypes = {
  columns: PropTypes.array.isRequired,
  dataSource: PropTypes.array.isRequired,
  sort: PropTypes.object,
  disabled: PropTypes.bool,
  nonNumericFields: PropTypes.object,
  showModified: PropTypes.bool,
  errorsVisible: PropTypes.bool,
  clientId: PropTypes.string,
  paymentId: PropTypes.string,
  activeCard: PropTypes.object,
  noResultsText: PropTypes.string,
  paymentsBlocked: PropTypes.bool,
  onDisplayErrors: PropTypes.func,
  checkRowDisabled: PropTypes.func,
  centered: PropTypes.bool,
  onSort: PropTypes.func,
  hasActionAccess: PropTypes.func,
  modalConfirmSuccess: PropTypes.func,
  mandateHistoryLoadingForId: PropTypes.string,
  scheduleHistoryLoadingForId: PropTypes.string,
  country: PropTypes.string,
  onLinkClick: PropTypes.func,
  onDeleteDevice: PropTypes.func,
  reloadScheduledPayments: PropTypes.func,
  reloadMandates: PropTypes.func,
  onViewScheduleDetailsClick: PropTypes.func,
  onViewMandateDetailsClick: PropTypes.func,
};

export default DataGrid;
