import { CURRENCY_CODES, CUSTOM_FIELD_TYPES } from 'variables';
import {
  LIMIT_PAYMENT_TYPE_ATM_BALANCE,
  LIMIT_PAYMENT_TYPE_ATM_CHANGE_PIN,
  LIMIT_PAYMENT_TYPE_LABEL,
} from './constants';
import { formatCurrency } from './service';

const PAYMENT_TYPE = {
  label: '',
  name: 'payment_type',
  style: {
    minWidth: 205,
  },
  contentCellStyle: {
    height: 68,
  },
  getValue: (record = {}, name) => {
    const paymentType = record[name];
    const label = LIMIT_PAYMENT_TYPE_LABEL[paymentType];

    return label || undefined;
  },
  showHierarchy: true,
};

const MAX_NUMBER = {
  label: 'Max number',
  name: 'max_number',
  textProps: {
    variant: 'highlight',
    size: 'one',
  },
  contentCellStyle: {
    height: 68,
  },
};

const getReadonlyLimitValue = (record = {}, name, { country }) => {
  if (
    record.payment_type === LIMIT_PAYMENT_TYPE_ATM_BALANCE ||
    record.payment_type === LIMIT_PAYMENT_TYPE_ATM_CHANGE_PIN
  ) {
    return null;
  }

  const currency = country === 'GB' ? CURRENCY_CODES.GBP : CURRENCY_CODES.EUR;

  return record[name] != null
    ? formatCurrency(record[name], currency, { maximumFractionDigits: 0 })
    : record[name];
};

const MAX_AMOUNT = {
  label: 'Daily amount',
  name: 'max_amount',
  textProps: {
    variant: 'highlight',
    size: 'one',
  },
  contentCellStyle: {
    height: 68,
  },
  getValue: getReadonlyLimitValue,
};

const MAX_SINGLE_AMOUNT = {
  label: 'Single amount',
  name: 'max_single_amount',
  textProps: {
    variant: 'highlight',
    size: 'one',
  },
  contentCellStyle: {
    height: 68,
  },
  getValue: getReadonlyLimitValue,
};

export const cardLimitsColumns = [
  PAYMENT_TYPE,
  MAX_NUMBER,
  MAX_SINGLE_AMOUNT,
  MAX_AMOUNT,
];

const getEditableLimitValue = (record = {}, fieldName) => {
  if (
    record.payment_type === LIMIT_PAYMENT_TYPE_ATM_BALANCE ||
    record.payment_type === LIMIT_PAYMENT_TYPE_ATM_CHANGE_PIN
  ) {
    return null;
  }

  return record[fieldName];
};

export const editableCardLimitsColumns = [
  PAYMENT_TYPE,
  {
    ...MAX_NUMBER,
    renderAs: CUSTOM_FIELD_TYPES.INPUT,
    getValue: undefined,
  },
  {
    ...MAX_SINGLE_AMOUNT,
    renderAs: CUSTOM_FIELD_TYPES.INPUT,
    getValue: getEditableLimitValue,
  },
  {
    ...MAX_AMOUNT,
    renderAs: CUSTOM_FIELD_TYPES.INPUT,
    getValue: getEditableLimitValue,
  },
];
