import { Input } from '@sumup/circuit-ui';
import styled from '@emotion/styled';

const StyledInputCell = styled(Input)(
  ({ theme }) => `
    height: 48px;
    margin: -${theme.spacings.bit} 0;
    padding: ${theme.spacings.byte};
  `
);

export default StyledInputCell;
