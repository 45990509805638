export const LOAD_SUCCESS = 'LOAD_SUCCESS';
export const LOAD_ERROR = 'LOAD_ERROR';
export const SET_LOADING = 'SET_LOADING';
export const SET_ERROR = 'SET_ERROR';

// Account
export const SET_CREATE_BANK_ACCOUNT_LOADING =
  'SET_CREATE_BANK_ACCOUNT_LOADING';
export const SET_PRIMARY_PSD_STATUS_LOADING = 'SET_PRIMARY_PSD_STATUS_LOADING';
export const SET_REPLACEMENT_PSD_STATUS_LOADING =
  'SET_REPLACEMENT_PSD_STATUS_LOADING';
export const SET_LOCK_CARD_LOADING = 'SET_LOCK_CARD_LOADING';
export const SET_PRIMARY_CARD_STATUS_LOADING =
  'SET_PRIMARY_CARD_STATUS_LOADING';
export const SET_REPLACEMENT_CARD_STATUS_LOADING =
  'SET_REPLACEMENT_CARD_STATUS_LOADING';
export const SET_ISSUE_CARD_LOADING = 'SET_ISSUE_CARD_LOADING';
export const SET_TRANSFER_TO_BANK_LOADING = 'SET_TRANSFER_TO_BANK_LOADING';
export const TRANSFER_TO_BANK_ERROR = 'TRANSFER_TO_BANK_ERROR';
export const SET_BALANCE_LOADING = 'SET_BALANCE_LOADING';
export const SET_ACCOUNT_STATUS = 'GET_ACCOUNT_STATUS';
export const SET_ACCOUNT_STATUS_LOADING = 'SET_ACCOUNT_STATUS_LOADING';
export const ACCOUNT_STATUS_ERROR = 'ACCOUNT_STATUS_ERROR';

// Client
export const GET_CLIENT_DATA_ERROR = 'GET_CLIENT_DATA_ERROR';
export const ISSUE_CARD_SUCCESS = 'ISSUE_CARD_SUCCESS';
export const ISSUE_CARD_ERROR = 'ISSUE_CARD_ERROR';
export const REPLACE_CARD_ERROR = 'REPLACE_CARD_ERROR';
export const REPLACE_CARD_SUCCESS = 'REPLACE_CARD_SUCCESS';
export const SET_REPLACE_CARD_LOADING = 'SET_REPLACE_CARD_LOADING';
export const SET_PRIMARY_CARD_STATUS_SUCCESS =
  'SET_PRIMARY_CARD_STATUS_SUCCESS';
export const SET_REPLACEMENT_CARD_STATUS_SUCCESS =
  'SET_REPLACEMENT_CARD_STATUS_SUCCESS';
export const SET_PRIMARY_CARD_STATUS_ERROR = 'SET_PRIMARY_CARD_STATUS_ERROR';
export const SET_REPLACEMENT_CARD_STATUS_ERROR =
  'SET_REPLACEMENT_CARD_STATUS_ERROR';
export const CREATE_BANK_ACCOUNT_SUCCESS = 'CREATE_BANK_ACCOUNT_SUCCESS';
export const CREATE_BANK_ACCOUNT_ERROR = 'CREATE_BANK_ACCOUNT_ERROR';
export const UPDATE_CLIENT_ERROR = 'UPDATE_CLIENT_ERROR';
export const CLEAR_PIN_ATTEMPTS_SUCCESS = 'CLEAR_PIN_ATTEMPTS_SUCCESS';
export const CLEAR_PIN_ATTEMPTS_ERROR = 'CLEAR_PIN_ATTEMPTS_ERROR';
export const SET_PRIMARY_PSD_STATUS_SUCCESS = 'SET_PRIMARY_PSD_STATUS_SUCCESS';
export const SET_REPLACEMENT_PSD_STATUS_SUCCESS =
  'SET_REPLACEMENT_PSD_STATUS_SUCCESS';
export const SET_PRIMARY_PSD_STATUS_ERROR = 'SET_PRIMARY_PSD_STATUS_ERROR';
export const SET_REPLACEMENT_PSD_STATUS_ERROR =
  'SET_REPLACEMENT_PSD_STATUS_ERROR';
export const LOCK_CARD_FOR_AUDIT_ERROR = 'LOCK_CARD_FOR_AUDIT_ERROR';

// PSD Challenges
export const SET_ACTIVE_CHALLENGES = 'SET_ACTIVE_CHALLENGES';

// Activity log
export const FILTER_ACTIVITY_LOG = 'FILTER_ACTIVITY_LOG';

// Transactions
export const SET_TRANSACTIONS_DATE_RANGE_FILTER =
  'SET_TRANSACTIONS_DATE_RANGE_FILTER';
export const SET_FILTERED_TRANSACTIONS = 'SET_FILTERED_TRANSACTIONS';
export const SET_TRANSACTIONS_PAGE = 'SET_TRANSACTIONS_PAGE';
export const SET_TRANSACTION_ORIGIN_FILTER = 'SET_TRANSACTION_ORIGIN_FILTER';
export const SET_TRANSACTION_DIRECTION_FILTER =
  'SET_TRANSACTION_DIRECTION_FILTER';
export const SET_TRANSACTION_FILTER = 'SET_TRANSACTION_FILTER';
export const APPLY_TRANSACTIONS_FILTERS = 'APPLY_TRANSACTIONS_FILTERS';
export const SET_TRANSACTIONS_PAGE_SIZE = 'SET_TRANSACTIONS_PAGE_SIZE';
export const CLEAR_TRANSACTIONS_FILTERS = 'CLEAR_TRANSACTIONS_FILTERS';
export const SORT_TRANSACTIONS = 'SORT_TRANSACTIONS';

// Limits
export const SET_CARD_LIMITS = 'SET_CARD_LIMITS';

// Direct debits
export const SET_DIRECT_DEBITS_ENABLED_SUCCESS =
  'SET_DIRECT_DEBITS_ENABLED_SUCCESS';
export const SET_DIRECT_DEBITS_ENABLED_ERROR =
  'SET_DIRECT_DEBITS_ENABLED_ERROR';
export const SET_DIRECT_DEBITS_ENABLED_LOADING =
  'SET_DIRECT_DEBITS_ENABLED_LOADING';
export const SET_DIRECT_DEBITS_EVENTS = 'SET_DIRECT_DEBITS_EVENTS';

// Direct debit mandates
export const SET_DIRECT_DEBIT_MANDATES_PAGE = 'SET_DIRECT_DEBIT_MANDATES_PAGE';
export const SET_DIRECT_DEBIT_MANDATES_PAGE_SIZE =
  'SET_DIRECT_DEBIT_MANDATES_PAGE_SIZE';
export const SET_DIRECT_DEBIT_MANDATES_STATUS_FILTER =
  'SET_DIRECT_DEBIT_MANDATES_STATUS_FILTER';
export const SORT_DIRECT_DEBIT_MANDATES = 'SORT_DIRECT_DEBIT_MANDATES';
export const SET_SELECTED_MANDATE = 'SET_SELECTED_MANDATE';
export const LOAD_MANDATE_TRANSACTION_HISTORY_SUCCESS =
  'LOAD_MANDATE_TRANSACTION_HISTORY_SUCCESS';
export const SET_MANDATE_TRANSACTION_HISTORY_LOADING =
  'SET_MANDATE_TRANSACTION_HISTORY_LOADING';
export const SET_MANDATE_UPCOMING_PAYMENT = 'SET_MANDATE_UPCOMING_PAYMENT';
export const SORT_DIRECT_DEBIT_MANDATE_HISTORY =
  'SORT_DIRECT_DEBIT_MANDATE_HISTORY';
export const CLEAR_MANDATE_TRANSACTION_HISTORY =
  'CLEAR_MANDATE_TRANSACTION_HISTORY';
export const SET_MANDATE_HISTORY_PAGE = 'SET_MANDATE_HISTORY_PAGE';
export const SET_MANDATE_HISTORY_PAGE_SIZE = 'SET_MANDATE_HISTORY_PAGE_SIZE';

// Ops transfers
export const SET_OPS_TRANSFERS_PAGE = 'SET_OPS_TRANSFERS_PAGE';
export const SET_OPS_TRANSFERS_PAGE_SIZE = 'SET_OPS_TRANSFERS_PAGE_SIZE';
export const SET_OPS_TRANSFERS_STATUS_FILTER =
  'SET_OPS_TRANSFERS_STATUS_FILTER';
export const SET_OPS_TRANSFERS_CURRENCY_FILTER =
  'SET_OPS_TRANSFERS_CURRENCY_FILTER';
export const SORT_OPS_TRANSFERS = 'SORT_OPS_TRANSFERS';

// Session
export const AUTH = 'AUTH';
export const SESSION_EXPIRED = 'SESSION_EXPIRED';

// Permissions
export const SET_PERMISSIONS = 'SET_PERMISSIONS';

// User administration
export const SELECT_USER = 'SELECT_USER';
export const FILTER_USERS = 'FILTER_USERS';
export const LOAD_GROUPS_SUCCESS = 'LOAD_GROUPS_SUCCESS';
export const LOAD_GROUPS_ERROR = 'LOAD_GROUPS_ERROR';
export const LOAD_GROUP_SUCCESS = 'LOAD_GROUP_SUCCESS';
export const LOAD_GROUP_ERROR = 'LOAD_GROUP_ERROR';
export const UPDATE_GROUP_SUCCESS = 'UPDATE_GROUP_SUCCESS';
export const UPDATE_GROUP_ERROR = 'UPDATE_GROUP_ERROR';
export const DELETE_GROUP_ERROR = 'DELETE_GROUP_ERROR';
export const SET_GROUPS_LOADING = 'SET_GROUPS_LOADING';
export const SET_GROUP_LOADING = 'SET_GROUP_LOADING';
export const SET_USERS_LOADING = 'SET_USERS_LOADING';
export const SELECT_GROUP = 'SELECT_GROUP';
export const SET_NEW_GROUP_NAME = 'SET_NEW_GROUP_NAME';
export const SET_NEW_GROUP_APP = 'SET_NEW_GROUP_APP';
export const SET_NEW_GROUP_MODULES = 'SET_NEW_GROUP_MODULES';
export const SET_NEW_GROUP_SECTIONS = 'SET_NEW_GROUP_SECTIONS';
export const SET_NEW_GROUP_ACTIONS = 'SET_NEW_GROUP_ACTIONS';
export const SET_SELECTED_GROUP_MODULES = 'SET_SELECTED_GROUP_MODULES';
export const SET_SELECTED_GROUP_SECTIONS = 'SET_SELECTED_GROUP_SECTIONS';
export const SET_SELECTED_GROUP_ACTIONS = 'SET_SELECTED_GROUP_ACTIONS';
export const SET_SELECTED_GROUP_NEW_NAME = 'SET_SELECTED_GROUP_NEW_NAME';
export const LOAD_APPS_SUCCESS = 'LOAD_APPS_SUCCESS';
export const LOAD_APPS_ERROR = 'LOAD_APPS_ERROR';
export const CLEAR_NEW_GROUP_DATA = 'CLEAR_NEW_GROUP_DATA';
export const SET_USER_GROUPS_LOADING = 'SET_USER_GROUPS_LOADING';
export const LOAD_USER_GROUPS_SUCCESS = 'LOAD_USER_GROUPS_SUCCESS';
export const LOAD_USER_GROUPS_ERROR = 'LOAD_USER_GROUPS_ERROR';
export const CREATE_GROUP_ERROR = 'CREATE_GROUP_ERROR';
export const CREATE_USER_ERROR = 'CREATE_USER_ERROR';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_ERROR = 'UPDATE_USER_ERROR';
export const DELETE_USER_ERROR = 'DELETE_USER_ERROR';
export const SET_SELECTED_USER_GROUPS = 'SET_SELECTED_USER_GROUPS';

// Reconciliation
export const SET_CLEARINGS_PAGE_SIZE = 'SET_CLEARINGS_PAGE_SIZE';
export const SET_CLEARINGS_FILTER = 'SET_CLEARINGS_FILTER';
export const SET_CLEARINGS_PAGE = 'SET_CLEARINGS_PAGE';
export const APPLY_CLEARINGS_FILTERS = 'APPLY_CLEARINGS_FILTERS';
export const CLEAR_CLEARINGS_FILTERS = 'CLEAR_CLEARINGS_FILTERS';
export const SORT_CLEARINGS = 'SORT_CLEARINGS';
export const LOAD_CARD_AUTHORIZATIONS = 'LOAD_CARD_AUTHORIZATIONS';
export const SET_CLEARINGS_DATE_RANGE_FILTER =
  'SET_CLEARINGS_DATE_RANGE_FILTER';
export const SET_AUTHORIZATIONS_PAGE_SIZE = 'SET_AUTHORIZATIONS_PAGE_SIZE';
export const SET_AUTHORIZATIONS_PAGE = 'SET_AUTHORIZATIONS_PAGE';
export const SET_AUTHORIZATIONS_FILTER = 'SET_AUTHORIZATIONS_FILTER';
export const SET_AUTHORIZATIONS_DATE_RANGE_FILTER =
  'SET_AUTHORIZATIONS_DATE_RANGE_FILTER';
export const UPDATE_AUTHORIZATIONS_FOR_ADJUSTMENT_SUCCESS =
  'UPDATE_AUTHORIZATIONS_FOR_ADJUSTMENT_SUCCESS';
export const APPLY_AUTHORIZATIONS_FILTERS = 'APPLY_AUTHORIZATIONS_FILTERS';
export const CLEAR_AUTHORIZATIONS_FILTERS = 'CLEAR_AUTHORIZATIONS_FILTERS';
export const SET_CANCEL_AUTHORIZATIONS_PAGE_SIZE =
  'SET_CANCEL_AUTHORIZATIONS_PAGE_SIZE';
export const SET_CANCEL_AUTHORIZATIONS_PAGE = 'SET_CANCEL_AUTHORIZATIONS_PAGE';
export const APPLY_CANCEL_AUTHORIZATIONS_FILTERS =
  'APPLY_CANCEL_AUTHORIZATIONS_FILTERS';
export const SET_CANCEL_AUTHORIZATIONS_DATE_FILTER =
  'SET_CANCEL_AUTHORIZATIONS_DATE_FILTER';
export const SET_CANCEL_AUTHORIZATIONS_FILTER =
  'SET_CANCEL_AUTHORIZATIONS_FILTER';
export const CLEAR_CANCEL_AUTHORIZATIONS_FILTERS =
  'CLEAR_CANCEL_AUTHORIZATIONS_FILTERS';
export const SORT_CANCEL_AUTHORIZATIONS = 'SORT_CANCEL_AUTHORIZATIONS';
export const SET_IGNORE_AUTHORIZATION_LOADING =
  'SET_IGNORE_AUTHORIZATION_LOADING';
export const SET_ADJUST_AUTHORIZATION_LOADING =
  'SET_ADJUST_AUTHORIZATION_LOADING';
export const REMOVE_IGNORE_AUTHORIZATION_LOADING =
  'REMOVE_IGNORE_AUTHORIZATION_LOADING';
export const REMOVE_ADJUST_AUTHORIZATION_LOADING =
  'REMOVE_ADJUST_AUTHORIZATION_LOADING';

// SCA devices
export const SET_SCA_DEVICES = 'SET_SCA_DEVICES';

// Scheduled payments
export const SET_SCHEDULED_PAYMENTS_PAGE = 'SET_OPS_TRANSFERS_PAGE';
export const SET_SCHEDULED_PAYMENTS_PAGE_SIZE = 'SET_OPS_TRANSFERS_PAGE_SIZE';
export const SET_SCHEDULED_PAYMENTS_STATUS_FILTER =
  'SET_SCHEDULED_PAYMENTS_STATUS_FILTER';
export const SET_SCHEDULED_PAYMENTS_TYPE_FILTER =
  'SET_SCHEDULED_PAYMENTS_TYPE_FILTER';
export const SET_SELECTED_SCHEDULED_PAYMENT = 'SET_SELECTED_SCHEDULED_PAYMENT';
export const LOAD_SCHEDULE_TRANSACTION_HISTORY_SUCCESS =
  'LOAD_SCHEDULE_TRANSACTION_HISTORY_SUCCESS';
export const CLEAR_SCHEDULE_TRANSACTION_HISTORY =
  'CLEAR_SCHEDULE_TRANSACTION_HISTORY';
export const SET_SCHEDULE_TRANSACTION_HISTORY_LOADING =
  'SET_SCHEDULE_TRANSACTION_HISTORY_LOADING';
export const SORT_SCHEDULE_HISTORY = 'SORT_SCHEDULE_HISTORY';
export const SET_SCHEDULE_HISTORY_PAGE = 'SET_SCHEDULE_HISTORY_PAGE';
export const SET_SCHEDULE_HISTORY_PAGE_SIZE = 'SET_SCHEDULE_HISTORY_PAGE_SIZE';
